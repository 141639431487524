import { useAuth0 } from "@auth0/auth0-react";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Avatar, Button, Popover } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { NavLink } from "react-router-dom";

import { RELEASE_NOTES_URL } from "~/constants/urls";
import { useOrganization, useUserInfo } from "~/hooks";
import { setAnchor } from "~/utils/dataUtils";

import { StyledButton } from "./styles";

export const SettingsDropDown = ({ dropdown }) => {
  const { selectedOrg } = useOrganization();
  const { user, isSuperuser, isAdmin } = useUserInfo();
  const [profileAnchor, setSettingsAnchor] = useState(null);

  const handleSettingsMenu = event => setSettingsAnchor(event.currentTarget);
  const handleSettingsClose = () => setSettingsAnchor(null);
  const { logout } = useAuth0();

  return (
    <>
      <Popover
        className={dropdown}
        {...setAnchor("bottom", "left")}
        anchorEl={profileAnchor}
        open={Boolean(profileAnchor)}
        onClose={handleSettingsClose}
      >
        <NavLink to="/account-settings/profile" onClick={handleSettingsClose}>
          <Button>Your Account</Button>
        </NavLink>

        {(isSuperuser || isAdmin) && (
          <NavLink
            to={`/organization-settings/${selectedOrg?.id}/management`}
            onClick={handleSettingsClose}
          >
            <Button>Organization Management</Button>
          </NavLink>
        )}

        {isSuperuser && (
          <NavLink
            to={`/organizations-administration`}
            onClick={handleSettingsClose}
          >
            <Button>Organizations Administration</Button>
          </NavLink>
        )}

        <a
          href={RELEASE_NOTES_URL}
          target="_blank"
          rel="noreferrer"
          onClick={handleSettingsClose}
        >
          <Button>Release Notes</Button>
        </a>
        <Button
          onClick={() => {
            localStorage.removeItem("accessToken");
            logout({ logoutParams: { returnTo: window.location.origin } });
          }}
        >
          Log Out
        </Button>
      </Popover>
      <StyledButton
        data-testid="profile-button"
        onClick={handleSettingsMenu}
        endIcon={<ExpandMoreIcon />}
        color="inherit"
      >
        <Avatar alt={user.nickname} src={user.picture} />
      </StyledButton>
    </>
  );
};

SettingsDropDown.propTypes = {
  dropdown: PropTypes.string
};
