import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useState } from "react";

import FieldSelection from "./FieldSelection";
import { useStyles } from "./styles";

export function ColumnVisibilitySetting({ columns, setColumns }) {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(true);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Column Visibility</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FieldSelection columns={columns} setColumns={setColumns} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

ColumnVisibilitySetting.propTypes = {
  columns: PropTypes.array,
  setColumns: PropTypes.func
};
