import { useSnackbar } from "@kuva/ui-components";

import { downloadCSV } from "~/components/Table/TableMenu/utils";

export const useCSVDownload = () => {
  const { showSnackbar } = useSnackbar();

  const downloadAsCSV = ({ rows, columns, sortSetting, filename }) => {
    const downloadSuccess = downloadCSV(
      { rows, columns, sortSetting },
      filename
    );

    downloadSuccess
      ? showSnackbar("CSV download was successful.")
      : showSnackbar("Failed to download CSV file.", {
          variant: "error"
        });
  };

  return { downloadAsCSV };
};
