import { useState } from "react";
import { saveAs } from "file-saver";

import { useSnackbar } from "@kuva/ui-components";

import AlarmAPI from "~/apis/AlarmAPI";

export const useGifDownload = () => {
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [snackbarKey, setSnackbarKey] = useState(0);

  const dowloadGifForAlarm = async ({ deviceId, alarmId, viewType }) => {
    setLoading(true);
    const key = showSnackbar("Preparing download... Don't close this tab", {
      variant: "info"
    });

    setSnackbarKey(key);
    try {
      const response = await AlarmAPI.getGifToDownload(
        deviceId,
        alarmId,
        viewType
      );

      if (response.status === 200 && response.data.url) {
        saveAs(response.data.url);
        showSnackbar("GIF downloaded!");
      } else throw Error();
    } catch (error) {
      showSnackbar("Error: GIF download failed", {
        variant: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  return { dowloadGifForAlarm, loading, snackbarKey };
};
