import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useUserInfo } from "~/hooks";
import {
  ApplicationSettingsActions,
  defaultSettings
} from "~/store/slices/application-settings";
import { selectUserAccountSettings } from "~/store/slices/application-settings/selectors";

export const useSettings = () => {
  const dispatch = useDispatch();
  const { userId } = useUserInfo();

  const userAccountSettings =
    useSelector(selectUserAccountSettings(userId), shallowEqual) ??
    defaultSettings;

  const {
    displayUtc,
    showNonDetections,
    showArchived,
    selectedResultsContainer,
    windSpeedUnit,
    leakRateUnit,
    imperialUnits,
    temperatureUnit
  } = userAccountSettings;

  const toggleShowNonDetections = useCallback(
    () =>
      dispatch(
        ApplicationSettingsActions.updateShowNonDetections({
          showNonDetection: !showNonDetections
        })
      ),
    [dispatch, showNonDetections]
  );

  const handleBlobContainerChange = useCallback(
    event =>
      dispatch(
        ApplicationSettingsActions.updateSelectedResultsContainer({
          selectedContainer: event.target.value
        })
      ),
    [dispatch]
  );

  const toggleDisplayUtc = useCallback(
    () =>
      dispatch(
        ApplicationSettingsActions.updateDisplayUtc({
          displayUtc: !displayUtc,
          userId
        })
      ),
    [dispatch, displayUtc, userId]
  );

  const updateImperialUnits = useCallback(
    () =>
      dispatch(
        ApplicationSettingsActions.setImperialUnits({
          imperialUnits: !imperialUnits,
          userId
        })
      ),
    [dispatch, imperialUnits, userId]
  );

  const setLeakRateUnit = useCallback(
    leakRateUnit =>
      dispatch(
        ApplicationSettingsActions.setLeakRateUnit({
          userId,
          leakRateUnit
        })
      ),
    [dispatch, userId]
  );

  const setTemperatureUnit = useCallback(
    temperatureUnit =>
      dispatch(
        ApplicationSettingsActions.setTemperatureUnit({
          userId,
          temperatureUnit
        })
      ),
    [dispatch, userId]
  );

  const setWindSpeedUnit = useCallback(
    windSpeedUnit =>
      dispatch(
        ApplicationSettingsActions.setWindSpeedUnit({
          userId,
          windSpeedUnit
        })
      ),
    [dispatch, userId]
  );

  const toggleShowArchived = useCallback(
    () =>
      dispatch(
        ApplicationSettingsActions.updateShowArchived({
          showArchived: !showArchived,
          userId
        })
      ),
    [dispatch, showArchived, userId]
  );

  return {
    toggleShowNonDetections,
    handleBlobContainerChange,
    toggleDisplayUtc,
    updateImperialUnits,
    toggleShowArchived,
    setLeakRateUnit,
    setTemperatureUnit,
    setWindSpeedUnit,
    showNonDetections,
    selectedResultsContainer,
    displayUtc,
    imperialUnits,
    leakRateUnit,
    windSpeedUnit,
    showArchived,
    userAccountSettings,
    temperatureUnit
  };
};
