import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useState } from "react";

import { ToggleButtonGroup } from "../ToggleButtonGroup";

import { useExportOptionStyles } from "./styles";

const EXPORT_TO_PDF = "pdf";
const EXPORT_TO_CSV = "csv";

export const ExportOption = ({ handleDownloadToCSV, handleDownloadToPDF }) => {
  const { classes } = useExportOptionStyles();
  const [expanded, setExpanded] = useState(true);
  const [exportType, setExportType] = useState(EXPORT_TO_PDF);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onExportTypeChange = (event, newValue) => {
    setExportType(newValue);
  };

  return (
    <Box className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Export Options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <ToggleButtonGroup
              className={classes.exportToggle}
              orientation="vertical"
              customTheme="secondary"
              onToggleChange={onExportTypeChange}
              value={exportType}
            >
              {handleDownloadToPDF && (
                <ToggleButton
                  className={classes.actionButton}
                  value={EXPORT_TO_PDF}
                  onClick={handleDownloadToPDF}
                >
                  Export to PDF
                </ToggleButton>
              )}
              {handleDownloadToCSV && (
                <ToggleButton
                  className={classes.actionButton}
                  value={EXPORT_TO_CSV}
                  onClick={handleDownloadToCSV}
                >
                  Export to CSV
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

ExportOption.propTypes = {
  handleDownloadToCSV: PropTypes.func,
  handleDownloadToPDF: PropTypes.func
};
