export const SOURCE_TAGS = [
  "Tanks",
  "Compressors",
  "Separators",
  "Flares",
  "Tanker Truck",
  "Off site",
  "Out-of-scene",
  "Other"
];
