import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import PropTypes from "prop-types";

import LoadingIndicator from "~/common/components/LoadingIndicator";
import LoadingOverlay from "~/common/components/LoadingOverlay";
import TruncatedTextWithTooltip from "~/components/TruncatedTextWithTooltip";

import TableHeaderCell from "./TableHeaderCell";
import TablePaginationActions from "./TablePaginationActions";
import { useTableStyles } from "./styles";

const invertDirection = {
  asc: "desc",
  desc: "asc"
};

/* spec: minimum column width is 240px */
const BORDER = 1; // 0.5 left & right
const PADDING = 20; // 10 left & right

export const ROWS_PER_PAGE = 25;
export const textMinWidth = 240 - (BORDER + PADDING) + "px";
export const Table = ({
  rows,
  columns,
  setColumns,
  sortSetting,
  setSortSetting,
  handleUnitsChange,
  onClickRow,
  units = {},
  getByPage,
  total,
  loading,
  page = 1
}) => {
  const { classes } = useTableStyles();
  const handlePageChange = (event, newPage) =>
    getByPage(newPage, ROWS_PER_PAGE);

  const handleSort = (
    columnName,
    isNumeric = false,
    isTShirtSize = false,
    isPoi = false
  ) => {
    setSortSetting({
      isNumeric,
      columnToSort: columnName,
      sortDirection:
        invertDirection[
          Array.isArray(sortSetting.sortDirection)
            ? sortSetting.sortDirection?.[0]
            : sortSetting.sortDirection
        ],
      isTShirtSize,
      isPoi
    });
  };

  const hasPagination = rows.length > 0 && getByPage && total > ROWS_PER_PAGE;

  return (
    <>
      <TableContainer className={classes.root}>
        <MaterialTable className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {columns?.map((col, index) => {
                return col?.columnVisibility ? (
                  col?.renderHeaderCellComponent ? (
                    <TableCell key={col?.headerName ?? index}>
                      <col.renderHeaderCellComponent>
                        <TruncatedTextWithTooltip minWidth={textMinWidth}>
                          {col?.headerName}
                        </TruncatedTextWithTooltip>
                      </col.renderHeaderCellComponent>
                    </TableCell>
                  ) : (
                    <TableHeaderCell
                      key={`${col?.headerName}-${index}`}
                      columnIdx={index}
                      headerName={col?.headerName}
                      unitsOfMeasurement={col?.unitsOfMeasurement}
                      sortable={col?.sortable}
                      handleSort={() =>
                        handleSort(
                          col.field,
                          col.isNumeric,
                          col.isTShirtSize,
                          col.isPoi
                        )
                      }
                      columnToSort={sortSetting?.columnToSort}
                      sortDirection={sortSetting?.sortDirection}
                      field={col?.field}
                      handleUnitsChange={e =>
                        handleUnitsChange(e, col?.fieldUnit)
                      }
                      units={units[col?.fieldUnit]}
                      fieldUnit={col?.fieldUnit}
                      withColumnMenu={col?.withColumnMenu}
                      withUnitsOptions={col?.withUnitsOptions}
                      withVisibilityOptions={col?.withVisibilityOptions}
                      columns={columns}
                      setColumns={setColumns}
                      sortIcon={col?.sortIcon}
                    />
                  )
                ) : null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => (
              <TableRow
                key={row.id}
                onClick={() => onClickRow(row)}
                className={onClickRow && classes.clickableRow}
              >
                {columns?.map(col => {
                  return col?.columnVisibility ? (
                    col?.renderCellComponent ? (
                      <TableCell key={`${row?.id}-${col.field}`}>
                        <col.renderCellComponent
                          row={row}
                          className={classes.cameraNameRenderCell}
                        />
                      </TableCell>
                    ) : (
                      <TableCell key={`${row?.id}-${col.field}`}>
                        {row[col?.field]}
                      </TableCell>
                    )
                  ) : null;
                })}
              </TableRow>
            ))}
          </TableBody>
        </MaterialTable>
      </TableContainer>
      {hasPagination &&
        (loading ? (
          <LoadingOverlay>
            <LoadingIndicator />
          </LoadingOverlay>
        ) : (
          <TablePagination
            component="div"
            labelDisplayedRows={() => ""}
            count={total || rows.length}
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            rowsPerPage={ROWS_PER_PAGE}
            page={page}
            labelRowsPerPage=""
            onPageChange={handlePageChange}
            ActionsComponent={TablePaginationActions}
          />
        ))}
    </>
  );
};

Table.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  setColumns: PropTypes.func,
  sortSetting: PropTypes.object,
  setSortSetting: PropTypes.func,
  handleUnitsChange: PropTypes.func,
  onClickRow: PropTypes.func,
  units: PropTypes.object,
  getByPage: PropTypes.func,
  page: PropTypes.number,
  total: PropTypes.number,
  selectedOrgCameras: PropTypes.array,
  loading: PropTypes.bool,
  toSort: PropTypes.bool
};
